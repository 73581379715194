<template>
    <div class="login">
        <img class="bannerImg" src="../assets/images/loginBannar.png" alt="">
        <div class="background">
            <div class="loginFrom page_wrap">
                <div class="page_flex">
                    <div class="left">
                        <p class="registerTitle">现在注册即可加入千元算法计划!</p>
                        <el-form ref="registerForm" label-position="left" label-width="0.8rem" style="margin-top: 0.2rem">
                            <el-form-item label="邮箱:" prop="username">
                                <el-input v-model="loginForm.username"></el-input>
                            </el-form-item>
                            <el-form-item v-if="!isLogin" prop="realName" label="姓名:">
                                <el-input v-model="loginForm.realName"></el-input>
                            </el-form-item>
                            <el-form-item v-if="!isLogin" label="手机号:" prop="phone">
                                <el-input v-model="loginForm.phone"></el-input>
                            </el-form-item>
                            <el-form-item label="密码:" prop="password">
                                <el-input type="password" v-model="loginForm.password"></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="button">
                            <el-button v-if="isLogin" :loading="loading" style="width: 2rem;background-color: #196ECE;color: #fff" @click="login">登录</el-button>
                            <el-button v-if="!isLogin" :loading="loading" style="width: 2rem;background-color: #196ECE;color: #fff" @click="register">立即注册</el-button>
                            <el-button v-if="!isLogin" :disabled="loading" type="primary" style="position: relative;left: 100px" @click="isLogin = true">去登录<i class="el-icon-right"></i></el-button>
                            <el-button v-if="isLogin" :disabled="loading" type="primary" style="position: relative;left: 100px" @click="isLogin = false">去注册<i class="el-icon-right"></i></el-button>
                        </div>
                    </div>
                    <div class="right">
                        <img src="../assets/images/wxlogo.png" alt="">
                        <p>扫描二维码获取更多信息</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios';

import userService from '@/api/api.js';
export default {
    name: 'Home',
    watch: {
        'isLogin' (val) {
            this.loginForm = {}
        }
    },
    data () {
        return {
            loading: false,
            isLogin: false,
            loginForm: {
                username: '',
                phone: '',
                password: '',
                realName: '',
                companyName: ''
            }
        }
    },
    mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    methods: {
        toRoute (val) {
            this.$router.push(val)
        },
        login() {
            this.loading = true
            let params = {
                ...this.loginForm,
                password: this.$encryptedData(this.loginForm.password),
                type: 1,
                platformType:1001
            }
            userService.login(params).then(res => {
                if (res.code == 200) {
                    this.$message.success('登录成功！')
                    window.localStorage.setItem('authorization',res.data.accessToken)
                    window.localStorage.setItem('refresh_token',res.data.refreshToken)
                    window.localStorage.setItem('username',res.data.username)
                    if(this.$route.query.from){
                        this.$router.push({
                            path:this.$route.query.from,
                            query:{
                             id:this.$route.query.newsDetailId   
                            }
                          })
                    }
                this.onload()
                } else {
                    this.$message.warning(res.msg)
                }
                this.loading = false;
            })
                .catch(err => {
                    this.loading = false;
                });
        },
        register() {
            this.loading = true
            let params = {
                ...this.loginForm,
                password: this.$encryptedData(this.loginForm.password),
                type: 1,
                
            }
            userService.register(params).then(res => {
                if (res.code == 200) {
                    this.$message.success('注册成功！')
                }
                this.loading = false;
            })
                .catch(err => {
                    this.loading = false;
                });
        }
    }

}
</script>
<style lang="scss" >
.el-message {
    font-size: 18px;
}
.login {
    text-align: center;
    width: 100%;
    margin: 0 auto;
    .background {
        width: 100%;
        height: 5rem;
        background-image: url("../assets/images/login.png");
        margin: 0.5rem auto 0;
        overflow: hidden;
        padding-top: 1.5rem;
    }
    .loginFrom {
        width: 80%;
        height: 3rem;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px 0px rgba(21, 94, 176, 0.31);
        opacity: 0.82;
        margin: 0 auto;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid #ccc;
        .left {
            width: 59%;
            height: 100%;
            margin-top: 0.1rem;
            text-align: left;
            float: left;
            padding: 0 0.1rem;
            border-right: 1px solid #2e95d1;
            .el-button--mini, .el-button--small {
                font-size: 0.12rem;
                border-radius: 0.03rem;
                padding: 0.09rem 0.15rem;
            }
            .el-form-item__label {
                font-size: 0.14rem;
                height: 0.3rem;
                padding: 0;
                line-height: 0.3rem;
            }

            .el-input__inner {
                height: 0.3rem;
                line-height: 0.3rem;
                font-size: 0.1rem;
                padding: 0 0.15rem
            }

            .el-form-item__content {
                line-height: 0.3rem;
            }

            .el-form-item {
                margin-bottom: 0.2rem;
            }
            .button {
                text-align: center;
                margin-top: 0.2rem;
            }
        }
        .right {
            width: 30%;
            height: 100%;
            float: left;
            img {
                height: 2.5rem;
                margin: 0.25rem auto 0 auto;
            }
        }
        .registerTitle {
            text-align: center;
            font-size: 0.24rem;
            color: #196ECE;
        }
    }
}

</style>
